import React from 'react';

import Switch from '../Switch';

const Toolbar = (props) => {
  const {
    active,
    cancel,
    handleChange,
    isToggle,
    save,
    subTitle,
    title,
    activeCredit,
    next,
    back,
    checklistType
  } = props;



  return (
    <div className="toolbar">
      {cancel ? (
        <button className="cancel-button" onClick={cancel}>
          Cancel
        </button>
      ) : (
        ''
      )}

      {back ? (
        <button className="cancel-button" onClick={back}>
          Back
        </button>
      ) : (
        ''
      )}

      {subTitle ? (
        <div>
          <label className="header-duo">{title}</label>
          <label className="header-sub-text">{subTitle}</label>
        </div>
      ) : (
        <label className="page-header">{''}</label>
      )}

      <div className={isToggle ? 'switch-frame' : 'hide'}>
        <div className={`switch-label ${active ? 'hint' : 'black'}`}>
          Inactive
        </div>

        <Switch
          defaultValue={active}
          onChange={(enabled) => handleChange('active', enabled)}
        />

        <div className={`switch-label ${active ? 'black' : 'hint'}`}>
          Active
        </div>
      </div>

      {activeCredit !== undefined && (
        <label
          className="page-header"
          style={{ position: 'absolute', left: '1000px', color: 'red' }}
        >
          {activeCredit === 0
            ? '0 Credit Points'
            : `${activeCredit} Credit Points`}
        </label>
      )}

      {save ? (
        <button className="save-button" onClick={() => {
          console.log("Checklist type:", checklistType);
          console.log("Title:", title);
          console.log("SubTitle:", subTitle);
          console.log("Active:", active);
          console.log("Active credit:", activeCredit);
          save();
        }}>
          Save
        </button>
      ) : (
        ''
      )}

      {next ? (
        <button className="save-button" onClick={next}>
          Next
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default Toolbar;

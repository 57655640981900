import React from 'react';

import _ from 'lodash';

import BundlesContainer from '../../containers/Dashboard/Bundles';
import ClientsContainer from '../../containers/Dashboard/Clients';
import ChecklistsContainer from '../../containers/Dashboard/Checklists';
import CoursesContainer from '../../containers/Dashboard/Courses';
import Documents from '../../components/Dashboard/Documents';
import Roster from '../../components/Dashboard/Roster';
import TestsContainer from '../../containers/Dashboard/Tests';
import Users from '../../components/Dashboard/Users';
import NotFound from '../../containers/NotFound';

import LoadingPane from '../Shared/LoadingPane';
import PopoverPane from '../Shared/PopoverPane';
import FeedbackContainer from '../../containers/Dashboard/Feedback';
import JobPostsContainer from '../../containers/Dashboard/JobPosts';
import PackagesContainer from '../../containers/Dashboard/Packages';

import logo from '../../theme/images/hire-logo-2x.png'



const tabs = (admin, role) => {
  const defaultTabs = [
    { name: 'Roster', route: 'roster' },
    { name: 'Tests', route: 'tests' },
    { name: 'Checklists', route: 'checklists' },
    { name: 'Mandatories', route: 'mandatories' },
    { name: 'Documents', route: 'documents' },
    { name: 'Bundles', route: 'bundles' },
    { name: '360 Feedback', route: '360Feedback' },
    { name: 'Job Posts', route: 'jobposts' },
    { name: 'Packages', route: 'packages' },
  ];

  const arr = defaultTabs.filter((t) => {
    // recruiter does not have access to bundles
    if (t.route === 'bundles' && role.name === 'recruiter') {
      return false;
    } else if (t.route === 'documents' && role.name === 'corporate') {
      return true;
    } else if (t.route === 'bundles') {
      // access to wallet
      return true;
    } else if (role.org.modules.includes(t.route) || t.route === 'roster') {
      // check if the module has been "turned" on OR roster tab (always on)
      return true;
    } else if (t.route === '360Feedback') {
      return true;
    } else if (t.route === 'jobposts') {
      return true;
    } else if (t.route === 'packages') {
      return true;
    } else {
      return false;
    }
  });

  return admin
    ? [
      { name: 'Clients', route: 'clients' },
      { name: 'Users', route: 'users' },
      ...arr,
    ]
    : arr;
};

const Dashboard = (props) => {
  const {
    goToOrgRoute,
    goToProfileRoute,
    goToTabRoute,
    handleChange,
    params,
    logout,
    state,
  } = props;
  const { admin, firstName, loading, isRole, lastName, roles, role } = state;

  if (
    !role ||
    role.name === 'nurse' ||
    role.org.status.toLowerCase() === 'inactive'
  ) {
    return <NotFound></NotFound>;
  }
  const {
    org: { attachment, title },
  } = role;

  const allowedRoles = ['corporate', 'super_admin', 'admin', 'recruiter'];
  const adminRoles = roles.filter((r) => allowedRoles.includes(r.name));
  const roleSwitches = adminRoles.filter(
    (r) => r.org.id !== params.id && r.org.status.toLowerCase() === 'active',
  );

  const logoSrc = attachment && false ? attachment.url : logo; // remove the false

  return (
    <div className="dashboard-view">
      {loading ? <LoadingPane /> : null}

      <div className="toolbar">
        <div className="company-logo">
          <img
            className="logo"
            src={logoSrc}
            alt="una-logo"
            style={{
              height: attachment && title.toLowerCase() !== 'una' ? 26 : 18,
            }}
          />
        </div>

        <div
          className={adminRoles.length > 1 ? 'current-client-frame' : 'hide'}
        >
          <div className="role">{role.org.title}</div>

          <button
            className="switch-clients-button red bold"
            onClick={() => {
              if (roleSwitches.length === 1) {
                goToOrgRoute(roleSwitches[0]);
              } else {
                handleChange('isRole', !isRole);
              }
            }}
          >
            Switch Clients
          </button>

          <div className={isRole ? 'roles-list-frame' : 'roles-list-frame sm'}>
            <div
              className="background"
              onClick={() => handleChange('isRole', false)}
            />

            <ul className="roles-list-view scroll">
              {_.orderBy(roleSwitches, ['org.title'], ['asc']).map((role) => {
                const { id, org } = role;
                if (role.name === 'nurse') {
                  return null;
                }
                return (
                  <li
                    className="role-cell"
                    key={id}
                    onClick={() => goToOrgRoute(role)}
                  >
                    <div className="role-label">{org.title}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <PopoverPane
          className="user-pane"
          node={
            <div className="user-button">
              <div className="role-frame">
                <div className="user-name bold">{`${firstName} ${lastName}`}</div>
                <div className="user-name red">{_.startCase(role.name)}</div>
              </div>

              <div className="down-arrow-icon" />
            </div>
          }
          children={
            <div>
              <button
                className="popover-button blue"
                onClick={goToProfileRoute}
              >
                My Profile
              </button>
              <button className="popover-button red" onClick={logout}>
                Logout
              </button>
            </div>
          }
        />
      </div>

      <div className="secondary-toolbar">
        <ul className="tab-frame">
          {tabs(admin, role).map((tab, i) => {
            const { name, route } = tab;
            const color = role.org.brandColor || '#FF5A5F';
            const selected =
              params.tab === '360Feedback'
                ? params.tab === name.replace(' ', '')
                : params.tab === 'jobposts'
                  ? params.tab === name.toLowerCase().replace(' ', '')
                  : params.tab === name.toLowerCase();
            const hexToRGB = (hex, alpha) => {
              const r = parseInt(hex.slice(1, 3), 16);
              const g = parseInt(hex.slice(3, 5), 16);
              const b = parseInt(hex.slice(5, 7), 16);

              return `rgba(${r},${g},${b},${alpha || 1})`;
            };
            return (
              <li
                key={i}
                className={selected ? 'tab selected' : 'tab'}
                onClick={() => goToTabRoute(route)}
                style={{
                  background: selected ? hexToRGB(color, 0.1) : 'transparent',
                  borderColor: selected ? color : 'transparent',
                }}
              >
                <div className="tab-name">{name}</div>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="content-frame">
        {renderContentView(props)}

        <div className="version-label hint">{`V${global.version}`}</div>
      </div>
    </div>
  );
};

function renderContentView(props) {
  const { user } = props.state;
  const tab = props.params.tab;

  if (tab === 'clients') {
    return <ClientsContainer user={user} {...props} />;
  } else if (tab === 'mandatories') {
    return <CoursesContainer user={user} {...props} />;
  } else if (tab === 'checklists') {
    return <ChecklistsContainer user={user} {...props} />;
  } else if (tab === 'roster') {
    return <Roster user={user} {...props} />;
  } else if (tab === 'bundles') {
    return <BundlesContainer user={user} {...props} />;
  } else if (tab === 'users') {
    return <Users user={user} {...props} />;
  } else if (tab === 'documents') {
    return <Documents user={user} {...props} />;
  } else if (tab === '360Feedback') {
    return <FeedbackContainer user={user} {...props} />;
  } else if (tab === 'jobposts') {
    return <JobPostsContainer user={user} {...props} />;
  } else if (tab === 'packages') {
    return <PackagesContainer user={user} {...props} />;
  } else {
    return <TestsContainer user={user} {...props} />;
  }
}

export default Dashboard;

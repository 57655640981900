import { gql } from 'apollo-boost';

const OrgBundlesQuery = gql`
  query OrgBundlesQuery($id: ID!) {
    org(id: $id) {
      id
      bundles {
        id
        bundleType
        externalUniqKey
        checklists {
          id
        }
        courses {
          id
        }
        tests {
          id
        }
        title
        updatedAtIso
      }
    }
  }
`;

export default OrgBundlesQuery;

import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import ProgressBar from '../Shared/ProgressBar';
import logo from '../../theme/images/hire-logo-2x.png'

const color = (score) => {
  if (score === 4) {
    return 'bg-green';
  } else if (score === 3) {
    return 'bg-blue';
  } else if (score === 2) {
    return 'bg-red';
  } else {
    return 'bg-orange';
  }
};

const statuses = {
  finished: 'Completed',
};

const printTip = {
  safari: 'Print backgrounds',
  chrome: 'More settings > Background graphics',
};

const RosterChecklist = (props) => {
  const { back, handleAnimation, handleExpand, refs, state, user } = props;
  const {
    attachment,
    expand,
    firstName,
    lastName,
    specialties,
    checklist,
    checklistTaken,
  } = state;
  const { endedAt, status } = checklistTaken;

  const browser = navigator.userAgent.toLowerCase().includes('chrome')
    ? 'chrome'
    : 'safari';
  const specialty = specialties.find((s) => s.isPrimary);
  const arr = _.orderBy(checklist.categories, ['rank']);

  const overallScore = checklist.avgQuestionScore;

  const section = checklist.categories[0].sections[0];
  const scoreLabels = section.scoreLabels || global.scoreValues;
  const scoreValues = section.scoreValues || [1, 2, 3, 4];

  return (
    <div className="roster-checklist-view full-screen-view">
      <div className="toolbar">
        <button className={user ? 'cancel-button' : 'hide'} onClick={back}>
          Back
        </button>

        <div className="print-button" onClick={() => window.print()}>
          <img
            src={require('../../theme/images/print-icon-2x.png')}
            alt="print-icon"
          />
        </div>
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="logo-frame">
            <div className="company-logo">
              {attachment ? (
                <img src={attachment.url} alt="company-logo" />
              ) : null}
            </div>

            <div className="una-logo">
              <img
                src={logo}
                alt="una-logo"
              />
            </div>
          </div>

          <div className="profile-frame">
            <div className="section-frame">
              <div className="section-header">PROFESSIONAL</div>

              <div className="section-value">{`${firstName} ${lastName}`}</div>

              <div className={specialty ? 'section-subvalue' : 'hide'}>
                {specialty ? specialty.name : ''}
              </div>
            </div>

            <div className="section-frame">
              <div className="section-header">CHECKLIST</div>

              <div className="section-value">{checklist.title}</div>

              <div className={endedAt ? 'section-subvalue' : 'hide'}>{`${statuses[status]
                } - ${moment(endedAt).format('MMM DD, YYYY')}`}</div>
            </div>

            <div className="section-frame">
              <div className="section-header">OVERALL SCORE</div>

              <div className="section-value">{overallScore}</div>

              <button
                className="expand-button blue bold"
                onClick={() => handleExpand(!expand)}
              >
                {expand ? 'Collapse All' : 'Expand All'}
              </button>
            </div>

            <div className="signature-frame">
              <div className="section-header">
                I attest that these answers are true and have been rated
                according to my current level of knowledge, skills, abilities,
                and competency for this specialty.
              </div>
              <div className="section-value cursive">{`${firstName} ${lastName}`}</div>
              <div className="section-value">SIGNATURE</div>
            </div>
          </div>

          <div className="hint-label hint">{`Print tip: Enable the "${printTip[browser]}" option to view category graphics.`}</div>

          <div
            className={scoreValues ? 'scoring-key-frame' : 'hide'}
            ref={(ref) => (refs.scoringKey = ref)}
          >
            <div className="header-frame">
              <div className="header">scoring key</div>

              <div
                className="chevron-icon"
                onClick={() => handleAnimation('scoringKey', scoreValues)}
              >
                <img
                  src={require('../../theme/images/chevron-icon-2x.png')}
                  alt="chevron-icon"
                />
              </div>
            </div>

            <ul className="scoring-keys-list-view">
              {scoreValues.map((v, i) => {
                return (
                  <li className="scoring-key-cell" key={i}>
                    <div className={`value ${color(v)}`}>{v}</div>

                    <div className="label">{scoreLabels[i]}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="categories-frame">
            <div className="header">category breakdown</div>

            <ul
              className="categories-list-view"
              ref={(ref) => (refs.categories = ref)}
            >
              {arr.map((category, i) => {
                const { id, pct, sections, score, title } = category;
                const parentNode = `c-${i}`;

                return (
                  <li
                    className="category-cell"
                    key={id}
                    ref={(ref) => (refs[parentNode] = ref)}
                  >
                    <div className="flex-frame category">
                      <div className="question-label title bold">{title}</div>

                      <div className="flex-frame">
                        <div className="question-label">{score}</div>

                        <ProgressBar pct={pct} />

                        <div
                          className="chevron-icon"
                          onClick={() => handleAnimation(parentNode, category)}
                        >
                          <img
                            src={require('../../theme/images/chevron-icon-2x.png')}
                            alt="chevron-icon"
                          />
                        </div>
                      </div>
                    </div>

                    <ul className="sections-list-view">
                      {_.orderBy(sections, ['rank']).map((section, idx) => {
                        const { id, pct, questions, score, title } = section;
                        const node = `c-${i} s-${idx}`;

                        return (
                          <li
                            className={`section-cell ${idx}`}
                            key={id}
                            ref={(ref) => (refs[node] = ref)}
                          >
                            <div className="flex-frame section">
                              <div className="question-label title">
                                {title}
                              </div>

                              <div className="flex-frame">
                                <div className="question-label">{score}</div>

                                <ProgressBar pct={pct} />

                                <div
                                  className="chevron-icon"
                                  onClick={() =>
                                    handleAnimation(node, section, parentNode)
                                  }
                                >
                                  <img
                                    src={require('../../theme/images/chevron-icon-2x.png')}
                                    alt="chevron-icon"
                                  />
                                </div>
                              </div>
                            </div>

                            <ul className="questions-list-view">
                              {_.orderBy(questions, ['value', 'rank']).map(
                                (question) => {
                                  const { id, title, value } = question;

                                  return (
                                    <li className="question-cell" key={id}>
                                      <div className="flex-frame">
                                        <div className="question-label">
                                          {title}
                                        </div>

                                        <div
                                          className={`question-score ${color(
                                            value,
                                          )}`}
                                        >
                                          {value}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                },
                              )}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RosterChecklist;

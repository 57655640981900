import { gql } from 'apollo-boost';

const BundleQuery = gql`
  query BundleQuery($id: ID!, $bundle: ID, $status: [String!]) {
    bundle(id: $bundle) {
      id
      bundleType
      externalUniqKey
      checklists {
        id
        title
      }
      courses {
        id
        title
      }
      kind
      tests {
        id
        title
      }
      title
    }
    org(id: $id) {
      id
      bundles {
        id
      }
      brandColor
      checklists(status: $status) {
        nodes {
          id
          title
        }
      }
      courses(status: $status) {
        nodes {
          id
          title
        }
      }
      secondaryColor
      tests(status: $status) {
        nodes {
          id
          title
        }
      }
    }
    specialties {
      id
      name
    }
  }
`;

export default BundleQuery;
